import { Injectable } from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {API_CURRENT_VERSION, EndPointBaseUrl} from "../settings/settings";
import {CommunicatorService} from "./communicator.service";
import {NotificationService} from "./notification.service";
import {ErrorMessageService} from "./error-message.service";
import {IRole} from "../models/common";

@Injectable({
  providedIn: "root"
})
export class RoleService {
  public roleList: IRole[];
  public roleListData = new Subject<IRole[]>();
  public roleListData$ = this.roleListData.asObservable();
  public roleListAsPerLoggedInUser = new Subject<IRole[]>();
  public roleListAsPerLoggedInUser$ = this.roleListAsPerLoggedInUser.asObservable();
  public majorRoleList: IRole[] = [];
  public majorRoleListData = new Subject<any>();
  public majorRoleListData$ = this.majorRoleListData.asObservable();
  public permissionOfSelectedRole;
  public permissionListOfSelectedRole = new Subject<any>();
  public permissionListOfSelectedRole$ = this.permissionListOfSelectedRole.asObservable();
  private availableRolePermissions = {};

  constructor(private communicatorServiceObject: CommunicatorService,
              public notificationService: NotificationService,
              public errorMessageService: ErrorMessageService) { }

  getRoleList(id, list, create, source?: 'concurrent') {
    let queryParams = {};
    if(source){
      queryParams = { source }
    }else{
      if (id !== null && id !== undefined) {
        queryParams['id'] = id;
      }
      if (list !== null && list !== undefined) {
        queryParams['list'] = list;
      }
      if (create !== null && create !== undefined) {
        queryParams['create'] = create;
      }
    }

    queryParams = queryParams || {};
      let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
      if (queryString !== "") {
        queryString = "?" + queryString;
    }
    
    this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/common/roles" + queryString).subscribe(data => {
        if (data !== null && data !== undefined) {
          this.roleList = data.results;
          this.roleListData.next(this.roleList);
          this.cacheRolePermissions();
        }
      }, error => {
      if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
        this.notificationService.showError("Failed to process your request");
      } else {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
      }
      }
    );
  }

  getRolesByProjectIds(projectIds: number[]){
    const projectIdsParams = projectIds.map(id => `projectIds=${id}`).join('&');
    return this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
      "/common/roles?" + projectIdsParams);
  }

  cacheRolePermissions() {
    for (const role of this.roleList) {
      this.getPermissionsOfSelectedRole(role.id);
    }
  }

  getMajorRoleList(id, create) {
    if (this.majorRoleList !== undefined && this.majorRoleList !== null && this.majorRoleList.length > 0 ) {
      this.majorRoleListData.next(this.majorRoleList);
    } else {
      let queryParams = {};
      if (id !== null && id !== undefined) {
        queryParams['id'] = id;
      }
      if (create !== undefined && create !== null) {
        queryParams['create'] = create;
      }
      queryParams = queryParams || {};
      let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
      if (queryString !== "") {
        queryString = "?" + queryString;
      }
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/common/roles" + queryString).subscribe(data => {
          if (data !== null && data !== undefined) {
            this.majorRoleList = data.results;
            this.majorRoleListData.next(this.majorRoleList);
          }
        }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
        }
      );
    }

  }

  getPermissionsOfSelectedRole(roleId) {
    if (roleId !== null) {
      if (roleId in this.availableRolePermissions) {
        return this.availableRolePermissions[roleId];
      } else {
        return this.getPermissionsOfSelectedRoleFromServer(roleId);
      }
    }
  }

  getPermissionsOfSelectedRoleFromServer(roleId) {
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/common/roles/" + roleId).subscribe(data => {
          if (data !== null && data !== undefined) {
            this.availableRolePermissions[roleId] = data;
            this.permissionListOfSelectedRole.next(this.availableRolePermissions[roleId]);
          }
        }, error => {
          // const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(error);
          // this.notificationService.showError(manipulatedErrorMessage);
        }
      );
  }

  getRoleListAsPerLoggedInUserRole(id, list, create) {
    if (this.roleList !== undefined && this.roleList !== null && this.roleList.length > 0) {
      this.roleListAsPerLoggedInUser.next(this.roleList);
    } else {
      let queryParams = {};
      if (id !== null && id !== undefined) {
        queryParams['id'] = id;
      }
      if (list !== null && list !== undefined) {
        queryParams['list'] = list;
      }
      if (create !== null && create !== undefined) {
        queryParams['create'] = create;
      }
      queryParams = queryParams || {};
      let queryString = Object.keys(queryParams).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(queryParams[key])).join("&");
      if (queryString !== "") {
        queryString = "?" + queryString;
      }
      this.communicatorServiceObject.getData(EndPointBaseUrl + API_CURRENT_VERSION +
        "/common/roles" + queryString).subscribe(data => {
          if (data !== null && data !== undefined && data.results.length > 0) {
            this.roleList = data.results;
            this.roleListAsPerLoggedInUser.next(this.roleList);
            this.cacheRolePermissions();
          }
        }, error => {
        if (error.statusCode !== undefined && error.statusCode !== null && error.statusCode === 500) {
          this.notificationService.showError("Failed to process your request");
        } else {
          const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBarWithoutKey(error);
          if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
            this.notificationService.showError(manipulatedErrorMessage);
          }
        }
        }
      );
    }

  }
}
