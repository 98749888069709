import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import { MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {ListUsersService} from "../list-users/list-users.service";
import {ModifyUserService} from "./modify-user.service";
import {UserService} from "../../user.service";
import {
  Admin,
  AdminResource,
  API_CURRENT_VERSION, 
  ClientRelationshipManager,
  ClinicianRole,
  CoderRole,
  Concurrent,
  EducationResource,
  EndPointBaseUrl,
  GuidelineResource,
  LoginType,
  ManagerRole,
  ProductOwner,
  ProviderQueriesResource,
  ProviderResource,
  ProviderRole,
  QAL1Role,
  QAL2Role,
  QAL3Role,
  SuperUser,
  SuperUserAccessResource,
  SystemAdministrator,
  UserManagementResource
} from "../../../common/settings/settings";
import {RoleService} from "../../../common/services/role.service";
import {ListProjectsService} from "../../../project/management/list-projects/list-projects.service";
import {forkJoin, Subject, Subscription} from "rxjs";
import {NodeCheckEventArgs, TreeViewComponent} from "@syncfusion/ej2-angular-navigations";
import {CheckBoxComponent} from "@syncfusion/ej2-angular-buttons";
import {IUser} from "../model/user";
import {CustomValidators} from "ng2-validation";
import {IProject} from "../../../project/management/model/project-model";
import {IGenericHTTPListResponse} from "../../../common/models/common";
import {ExternalUserDialogComponent} from "./external-user-dialog/external-user-dialog.component";
import {ReassignSupervisorDialogComponent} from "./reassign-supervisor-dialog/reassign-supervisor-dialog.component";
import {TimezoneService} from "../../../common/services/timezone.service";
import {SystemClientsService} from "../../../common/services/system-clients.service";
import {DropDownListComponent, MultiSelectComponent} from "@syncfusion/ej2-angular-dropdowns";
import {SearchAdaptorService} from "../../../common/services/search-adaptor.service";
import {DataManager, Query} from "@syncfusion/ej2-data";
import {NotificationService} from "../../../common/services/notification.service";
import {ErrorMessageService} from "../../../common/services/error-message.service";
import { ListProvidersService } from "src/app/provider/management/list-providers/list-providers.service";
import { loginTypeValidator, roleValidator } from "./modify-user.validators";
import { takeUntil } from "rxjs/operators";


@Component({
  selector: "app-modify-user",
  templateUrl: "./modify-user.component.html",
  styleUrls: ["./modify-user.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class ModifyUserComponent implements OnInit, OnDestroy {
  readonly USER_TYPE_INTERNAL = 1;
  readonly USER_TYPE_EXTERNAL = 2;
  private clientProjects$ = [];
  public clientId: any;
  public updateUser$: Subscription;
  public selectedClient: number;

  constructor(private dialogRef: MatDialogRef<ModifyUserComponent>,
              private formBuilder: FormBuilder,
              private router: Router,
              public listUsersService: ListUsersService,
              public modifyUserService: ModifyUserService,
              public userService: UserService,
              public roleService: RoleService,
              public systemClients: SystemClientsService,
              public listProjectsService: ListProjectsService,
              private dialog: MatDialog,
              public timezoneService: TimezoneService,
              public route: ActivatedRoute,
              private notificationService: NotificationService,
              private errorMessageService: ErrorMessageService,public listProvidersService: ListProvidersService, private cdr: ChangeDetectorRef) {
  }

  public isEdit: string;
  public isProvider: boolean = false;
  public userForm: FormGroup;
  public timezoneList;
  public clientList;
  public adminPermissionList;
  public fields: object = {text: "name", value: "id"};
  public providerFields: object = {text: "provider", value: "id"};
  public height = "220px";
  public timezoneFields: object = {text: "value", id: "key"};
  public userId: number;
  public roleId: number;
  public showMarkAsSuperuserCheckbox = false;
  public showMajorRoleRoleDropdown = true;
  public showEmployeeTypeRadioButtons = false;
  public isUserTypeInternal = false;
  public majorRoleList;
  public generalSortOrder = "Ascending";
  public fteStatusList;
  public timezone$: Subscription;
  public selectedUserData$: Subscription;
  public supervisor$: Subscription;
  public majorRole$: Subscription;
  public client$: Subscription;
  public adminPermission$: Subscription;
  public allProviders$: Subscription;
  public role$: Subscription;
  public project$: Subscription;
  public fte$: Subscription;
  public mode: string;
  public adminPermissionMode: string;
  public permissions;
  public accessPermission;
  public accessPermissionForNodesChecked = [];
  public accessPermissions = [];
  public editAccessPermissions$: Subscription;
  public editAccessPermissionsForNodesCheckAndUncheck$: Subscription;
  @ViewChild("defaultCheck")
  public treeObj: TreeViewComponent;
  @ViewChild("check")
  public checkboxObj: CheckBoxComponent;
  public editAccessField: object = {};
  public showCheckBox = true;
  public isMajorRoleSelected = false;
  public forceInternalUser = false;
  public userLevelRole: number;
  public clientLevelRole: number;
  public projectLevelRole: number;
  public loggedInUserData$: Subscription;
  public listItem = [
    {
      name: "Internal",
      value: 1
    },
    {
      name: "External",
      value: 2
    }
  ];
  providerRoles = [
    {
      name: "CDI Reviewer",
      id: ClinicianRole
    },
    {
      name: "Provider",
      id: ProviderRole
    }
  ];

  public loggedInUserDetails: IUser;
  public rolePermissions$: Subscription;
  public newUser$: Subscription;
  public actionData$: Subscription;
  public isAdminRoleEnabled = true;
  public isFTEStatusEnabled = true;
  public isProjectRoleEnabled = true;
  public majorRole: number;
  public editUserData: IUser;
  public isAddNewClientIconDisabled = false;
  public isAddNewProjectIconDisabled = false;
  public isRemoveClientIconDisabled = false;
  public isRemoveProjectIconDisabled = false;
  public showAdminPermissionDropdown = true;
  public isEditAccessDisabled = false;
  public totalClientCountOnCreation = 1;
  public isMarkAsSupervisorChecked = false;
  public clientToBeDisable: number;
  public activeClientRole: number;
  public newProject = {
    id: null,
    ids: null,
    fteStatus: null,
    role: null,
    permissions: [],
    permissionIds: [],
    leafPermissions: [],
    copyOfLeafPermissions: [],
    showEditAccessFlyout: false,
    editAccessFields: null,
    roleList: []
  };

  public newClient = {
    id: null,
    role: null,
    permissions: [],
    projects: [this.newProject],
    projectList: []
  };

  public newUser = {
    firstName: null,
    lastName: null,
    email: [],
    phoneNumber: null,
    timezone: null,
    license: null,
    supervisor: [null],
    typeOfUser: [null],
    isSupervisor: false,
    majorRole: false,
    clients: [this.newClient],
    permissions: [null]
  };

  @ViewChild("timezoneDropdown")
  public timezoneDropdown: DropDownListComponent;
  @ViewChild("supervisorDropdown")
  public supervisorDropdown: DropDownListComponent;
  @ViewChild("majorRoleDropdown")
  public majorRoleDropdown: DropDownListComponent;
  @ViewChild("clientDropdown")
  public clientDropdown: DropDownListComponent;
  @ViewChild("adminPermissionDropdown")
  public adminPermissionDropdown: MultiSelectComponent;
  @ViewChild("projectDropdown")
  public projectDropdown: MultiSelectComponent;
  @ViewChild("fteStatusDropdown")
  public fteStatusDropdown: DropDownListComponent;
  @ViewChild("roleDropdown")
  public roleDropdown: DropDownListComponent;
  public supervisorQuery: Query;
  public supervisorFields: object = {text: "name", value: "id"};
  public supervisorList: DataManager = new DataManager({
    url: EndPointBaseUrl + API_CURRENT_VERSION + "/users/filter",
    adaptor: new SearchAdaptorService(),
    crossDomain: true
  });
  public loginTypeList: any[] = [{ id: 0, text: "SSO Login" }, { id: 1, text: "Password Login" }];
  public loginTypeFields: object = { text: "text", value: "id" };

  destroy$ = new Subject<void>()

  buildUserForm(userData?) {
    let theUserData: any;
    let theClientSection: any[];
    if (userData === undefined || userData === null) {
      theUserData = this.newUser;
      theClientSection = [this.getClientSection()];
    } else {
      theUserData = userData;
      theClientSection = [];
    }
    if (theUserData.clients === undefined && theUserData.clients === null) {
      theUserData.clients = [this.newClient];
    }
    if (theUserData.clients.length === 0) {
      theClientSection = [this.getClientSection()];
    }
    for (const clientData of theUserData.clients) {
      if (clientData && clientData.id) {
        if (clientData.role !== undefined && clientData.role !== null && clientData.role == ClientRelationshipManager) {
          this.majorRole = ClientRelationshipManager;
          this.isMajorRoleSelected = true;
        }
        if (clientData.role !== undefined && clientData.role !== null && clientData.role == SystemAdministrator) {
          this.majorRole = SystemAdministrator;
          this.isMajorRoleSelected = true;
        }
        this.clientProjects$.push(this.getProjectsOfClient(clientData.id));
      }
    }
    if (this.majorRole == ClientRelationshipManager) {
      for (const clientData of theUserData.clients) {
        clientData.role = this.majorRole;
      }
    }
    if (this.majorRole == SystemAdministrator) {
      for (const clientData of theUserData.clients) {
        clientData.role = this.majorRole;
      }
    }

    if(this.isProvider){
      this.userForm = this.formBuilder.group({
        email: [theUserData.email],
        timezone: [theUserData.timezone, [Validators.required]],
        loginType: new FormControl(0, [Validators.required]), 
        license: new FormControl(''),
        typeOfUser: theUserData.typeOfUser,
        isSupervisor: [theUserData.isSupervisor, [Validators.required]],
        clients: this.formBuilder.array(theClientSection),
      });
    }else{
      this.userForm = this.formBuilder.group({
        firstName: [theUserData.firstName, [Validators.required]],
        lastName: [theUserData.lastName, [Validators.required]],
        email: [theUserData.email, [Validators.required, CustomValidators.email]],
        phoneNumber: theUserData.phoneNumber,
        timezone: [theUserData.timezone, [Validators.required]],
        license: theUserData.license,
        loginType: [theUserData.loginType, [Validators.required]],
        supervisor: theUserData.supervisor,
        typeOfUser: theUserData.typeOfUser,
        isSupervisor: [theUserData.isSupervisor, [Validators.required]],
        majorRole: theUserData.majorRole,
        clients: this.formBuilder.array(theClientSection),
        permissions: new FormControl(null)
      });
    }
    this.subscribeToLoginTypeChange();

    forkJoin(this.clientProjects$).subscribe((clientProjectsResponses: [IGenericHTTPListResponse]) => {
        const clientsFormData = this.formBuilder.array([]);
        for (const clientDataIndex in theUserData.clients) {
          theUserData.clients[clientDataIndex].projectList = clientProjectsResponses[clientDataIndex].results;
          const clientFormData = this.getClientSection(theUserData.clients[clientDataIndex]);
          clientsFormData.push(clientFormData);
        }

        this.userForm.setControl('clients',clientsFormData);

        if (this.isEdit == "true") {
          const client = this.userForm.get("clients") as FormArray;
          client.controls.forEach(data => {
            if (data !== undefined && data !== null) {
              data.get("permissions").enable();
              const id = data.get("id").value;
              if (this.activeClientRole == SuperUser) {
                if (id == this.clientToBeDisable) {
                  data.disable();
                }
              }
            }
          });
        }
        if (this.clientLevelRole == SuperUser) {
          if (this.clientId == this.userId) {
            if (this.isEdit == "true") {
              (this.userForm.controls.clients as FormArray)
                .controls
                .forEach(control => {
                  control.disable();
                });
            }
          }
        }
        if (this.userLevelRole == ProductOwner) {
          if (this.roleId == ProductOwner) {
            if (this.isEdit === "true") {
              (this.userForm.controls.clients as FormArray)
                .controls
                .forEach(control => {
                  control.disable();
                });
            }
          }
        }
      },
      (err) => {
      });
    if (this.isEdit === "true") {
      this.userForm.get("typeOfUser").disable();
      if (this.userForm.get("typeOfUser").value == 2) {
        this.isAddNewClientIconDisabled = true;
      }
      if (this.clientLevelRole == SuperUser) {
        if (this.clientId == this.userId) {
          this.userForm.get("isSupervisor").disable();
          this.userForm.get("supervisor").disable();
          this.userForm.get("majorRole").disable();
          this.forceInternalUser = true;
          this.isAddNewClientIconDisabled = true;
          this.isAddNewProjectIconDisabled = true;
          this.isRemoveProjectIconDisabled = true;
          this.isRemoveClientIconDisabled = true;
          this.isEditAccessDisabled = true;
        }
      }
      if (this.userLevelRole == ProductOwner) {
        if (this.roleId == ProductOwner) {
          this.userForm.get("isSupervisor").disable();
          this.userForm.get("supervisor").disable();
          this.userForm.get("majorRole").disable();
          this.forceInternalUser = true;
          this.isAddNewClientIconDisabled = true;
          this.isAddNewProjectIconDisabled = true;
          this.isRemoveProjectIconDisabled = true;
          this.isRemoveClientIconDisabled = true;
          this.isEditAccessDisabled = true;
        }
      }
    }
  }

  private getClientSection(clientData?) {
    let theClientData = this.newClient;
    if (clientData !== undefined && clientData !== null) {
      theClientData = clientData;
    }
    const newForm = this.getClientSectionOf(theClientData);
    return newForm;
  }


  getProjectsOfClient(clientId) {
    const clientProject$ = this.listProjectsService.getAllProjectList(clientId, 1);
    return clientProject$;
  }


  getClientSectionOf(clientData) {
    clientData.copyOfProject = JSON.parse(JSON.stringify(clientData.projects));
    const projectsFormData = this.formBuilder.array([]);
    if (clientData.projects && clientData.projects.length > 0) {
      const imploededProjects = this.implodeProjectsBasedOnPermissions(clientData.projects);
      for (const imploededProject of imploededProjects) {
        const projectFormData = this.getProjectSection(imploededProject);
        projectsFormData.push(projectFormData);
      }
    } else {
      const projectFormData = this.getProjectSection(this.newProject);
      projectsFormData.push(projectFormData);
    }
    if(this.isProvider){
      const newForm = this.formBuilder.group({
        id: clientData.id,
        role: clientData.role,
        permissions: [{value: clientData.permissions, disabled: this.isAdminRoleEnabled}],
        projects: projectsFormData,
        providers:clientData.id,
        projectList: this.formBuilder.control(clientData.projectList),
        providersList: this.formBuilder.control(clientData.providerList),
      });
      if(this.isEdit === "true"){
        this.listProvidersService.getAllProvidersList(clientData.id, 1, null, "type,first_name,group_name,facility_name");
        this.allProviders$ = this.listProvidersService.allProvidersList$.subscribe(data => {
          if (data !== undefined && data !== null && data.length > 0) {
            var selectedProvider = data.find(x => x.email === this.editUserData.email);
            this.userForm.controls.clients['controls'][0].controls.providers.setValue(selectedProvider.id);
            this.userForm.controls.clients['controls'][0].controls.providersList.setValue(data);
          }
        });
      }
      return newForm;
    }else{
    const newForm = this.formBuilder.group({
      id: clientData.id,
      role: clientData.role,
      permissions: [{value: clientData.permissions, disabled: this.isAdminRoleEnabled}],
      projects: projectsFormData,
      projectList: this.formBuilder.control(clientData.projectList)
    });
    return newForm;
  }
  }

  private explodeProjectsBasedOnPermissions(implodedProjects) {
    /*const explodedProjects = [];
    for (const project in implodedProjects) {
      for (const projectId in project.ids) {
        const projectCopy = JSON.parse(JSON.stringify(project));
        projectCopy.id = projectId;
        projectCopy.ids = null;
        explodedProjects.push(projectCopy);
      }
    }
    return explodedProjects; */
  }

  private explodeProjectsForClients(clients) {
    for (const client of clients) {
      client.projects = this.explodeProjectsBasedOnPermissions(client.projects);
    }
  }

  private implodeProjectsBasedOnPermissions(projects: [IProject]) {
    for (const project of projects) {
      project.permissionIds = [];
      const permissions = project.leafPermissions;
      for (const permission of permissions) {
        project.permissionIds.push(permission);
      }

      project.permissionIds = [...new Set(project.permissionIds.map(item => item))];
      project.permissionIds.sort((a, b) => a - b);
      project.leafPermissions = project.permissionIds.map((a) => String(a));
      project.copyOfLeafPermissions = JSON.parse(JSON.stringify(project.leafPermissions));
    }
    for (let projectIndex = 0; projectIndex < projects.length; projectIndex++) {
      if (!("permissionCombinedWithOtherProject" in projects[projectIndex])) {
        projects[projectIndex].ids = [projects[projectIndex].id];
        for (let projectIndex2 = projectIndex + 1; projectIndex2 < projects.length; projectIndex2++) {
          if (projects[projectIndex].fteStatus !== undefined && projects[projectIndex].fteStatus !== null &&
            projects[projectIndex].role !== undefined && projects[projectIndex].role !== null) {
            if (projects[projectIndex].fteStatus === projects[projectIndex2].fteStatus &&
              projects[projectIndex].role === projects[projectIndex2].role) {
              if (projects[projectIndex].permissionIds.toString() === projects[projectIndex2].permissionIds.toString()) {
                projects[projectIndex].ids.push(projects[projectIndex2].id);
                projects[projectIndex2].permissionCombinedWithOtherProject = true;
              }
            }
          }
        }
      }
    }
    for (let projectIndex = projects.length - 1; projectIndex >= 0; projectIndex--) {
      if (projects[projectIndex].permissionCombinedWithOtherProject) {
        projects.splice(projectIndex, 1);
      }
    }
    return projects;
  }

  getProjectSection(projectData?) {
    let theProjectData = this.newProject;
    if (projectData !== undefined && projectData !== null) {
      theProjectData = projectData;
    }
    return this.getProjectSectionOf(theProjectData);
  }

  getProjectSectionOf(projectData) {
    if (projectData === undefined || projectData === null) {
      projectData = {};

    }
    if (projectData["editAccessFields"] === undefined || projectData["editAccessFields"] === null) {
      if (this.accessPermission !== undefined && this.accessPermission !== null) {

      }
      const newAccessPermissions = JSON.parse(JSON.stringify(this.accessPermission));
      projectData["editAccessFields"] = {
        dataSource: newAccessPermissions, id: "id", text: "name", hasChildren: "hasChild",
        parentID: "parentId"
      };
    }

    const projectControl = this.formBuilder.group({
      id: this.formBuilder.control(projectData.id),
      ids: this.formBuilder.control(projectData.ids),
      fteStatus: this.formBuilder.control({value: projectData.fteStatus, disabled: !this.isFTEStatusEnabled}),
      role: this.formBuilder.control({value: projectData.role, disabled: !this.isProjectRoleEnabled}, []),
      permissions: this.formBuilder.control(projectData.permissions),
      leafPermissions: this.formBuilder.control(projectData.leafPermissions),
      copyOfLeafPermissions: this.formBuilder.control(projectData.copyOfLeafPermissions),
      showEditAccessFlyout: this.formBuilder.control(false),
      editAccessFields: this.formBuilder.control(projectData.editAccessFields),
      roleList: this.formBuilder.control(projectData.roleList)
    })

    if (this.isEdit === "true") {
      this.setProjectRoleList(projectData.ids, (value) => {
          projectControl.get('roleList').patchValue(value.results);
  
          // setTimeout is used to trigger ui update after patchValue
          setTimeout(() => {
            projectControl.get('role').patchValue(projectData.role);
          }, 1)
        })
    }

    return projectControl;
  }

  buildClientForm(): FormGroup {
    return this.formBuilder.group({
      clients: this.formBuilder.array([this.getClientSection()]),
    });
  }

  ngOnInit() {
    this.selectedUserData$ = this.userService.selectedUserListDetails$.subscribe(userData => {
      if (userData !== undefined && userData !== null) {
        this.editUserData = JSON.parse(JSON.stringify(userData));
        let adminPermissions = [];
        for (const clientIndex in this.editUserData.clients) {
          adminPermissions = [];
          for (const permissionIndex in this.editUserData.clients[clientIndex].permissions) {
            if (this.editUserData.clients[clientIndex].permissions[permissionIndex].subResources.length === 0) {
              adminPermissions.push(this.editUserData.clients[clientIndex].permissions[permissionIndex]['id']);
            }
            for (const subPermissions in this.editUserData.clients[clientIndex].permissions[permissionIndex].subResources) {
              adminPermissions.push(this.editUserData.clients[clientIndex].permissions[permissionIndex].subResources[subPermissions].id);
              userData.clients[clientIndex].permissions = adminPermissions;
            }
          }
        }
        this.isCheckedOnEditAccessPermission(userData);
        this.buildUserForm(userData);
        this.userForm.patchValue(userData);
        setTimeout(() => {
          this.userForm.get("majorRole").setValue(this.majorRole);
        }, 1000);
      }
    });
    this.majorRole$ = this.roleService.majorRoleListData$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.majorRoleList = data;
      } else {
        this.majorRoleList = [];
        if(this.majorRoleDropdown) this.majorRoleDropdown.noRecordsTemplate = "No Records To Display";
      }
    });
    this.fte$ = this.userService.projectFteStatusList$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.fteStatusList = data;
      } else {
        this.fteStatusList = [];
        this.fteStatusDropdown.noRecordsTemplate = "No Records To Display";
      }
    });
    this.modifyUserService.modifySupervisor$.subscribe((data) => {
      if (data && data.action !== undefined && data.action !== null) {
        if (data.action === "change.supervisor.checkbox") {
          if (data.value === true) {
            this.isMarkAsSupervisorChecked = false;
          } else {
            this.isMarkAsSupervisorChecked = true;
          }
        }
      }
    });
    this.modifyUserService.modifyUserData$.subscribe((data) => {
      if (data && data.action !== undefined && data.action !== null) {
        if (data.action === "change.user.type") {
          if (data.value === "external") {
            this.userForm.get("typeOfUser").setValue(2);
            this.isUserTypeInternal = false;
          } else {
            this.userForm.get("typeOfUser").setValue(1);
            this.isUserTypeInternal = true;
          }
        }
      }
    });
    //take all data from query param
    this.route.queryParams.subscribe(params => {
      if (params['userId'] !== undefined && params['userId'] !== null) {
        this.userId = (params['userId']);
      }
      if (params['roleId'] !== undefined && params['roleId'] !== null) {
        this.roleId = (params['roleId']);
      }
      if (params['isEdit'] !== undefined && params['isEdit'] !== null) {
        this.isEdit = (params['isEdit']);
      }
      if (params['isProvider'] !== undefined && params['isProvider'] !== null) {
        this.isProvider = JSON.parse(params['isProvider']);
      }
      if (params['clientId'] !== undefined && params['clientId'] !== null) {
        this.clientId = (params['clientId']);
      }
    });
    this.newUser$ = this.modifyUserService.newUserData$.subscribe(data => {
      if (data !== undefined && data !== null) {
        if (data.isActive === true) {
          this.listUsersService.newUserData.next({type: "activeUser" , value: true});
        } else {
          this.listUsersService.newUserData.next({type: "inactiveUser" , value: true});
        }
        this.dialogRef.close();
        this.router.navigate(["/users/list"]);
      }
    });
    this.editAccessPermissions$ = this.listUsersService.editAccessList$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.accessPermission = data.filter(datum => datum.id !== ProviderQueriesResource);
      }
    });
    this.editAccessPermissionsForNodesCheckAndUncheck$ = this.listUsersService.editAccessListForNodesChecked$.subscribe(data => {
      if (data !== undefined && data !== null) {
        this.accessPermissionForNodesChecked = data;
      }
    });
    this.loggedInUserData$ = this.userService.loggedInUserPermissions$.subscribe(data => {
      this.loggedInUserDetails = data;
      if (data !== undefined && data !== null) {
        const activeClient = Number(sessionStorage.getItem("activeClient"));
        this.userLevelRole = data.role;
        for (const clientIndex in data.clients) {
          if (activeClient == data.clients[clientIndex].id) {
            this.activeClientRole = data.clients[clientIndex].role;
          }
          if (this.activeClientRole === SuperUser) {
            if (data.clients[clientIndex].role === null) {
              this.clientToBeDisable = data.clients[clientIndex].id;
            }
          }
        }
        if (data.role == ProductOwner) {
          if (data.typeOfUser == this.USER_TYPE_INTERNAL) {
            this.showEmployeeTypeRadioButtons = true;
          } else {
            this.showEmployeeTypeRadioButtons = false;
          }
        }
        if (data.role == ProductOwner) {
          this.showMarkAsSuperuserCheckbox = true;
        }
        if (data.typeOfUser !== undefined && data.typeOfUser !== null) {
          if (data.typeOfUser == this.USER_TYPE_INTERNAL) {
            this.isUserTypeInternal = true;
          } else {
            this.isUserTypeInternal = false;
          }
        } else {
          this.isUserTypeInternal = true;
        }
        for (const clientRole in data.clients) {
          this.clientId = data.id;
          this.selectedClient = data.clients[clientRole].id;
          if (activeClient == this.selectedClient) {
            this.clientLevelRole = data.clients[clientRole].role;
            for (const projectRole in data.clients[clientRole].projects) {
              this.projectLevelRole = (data.clients[clientRole].projects[projectRole].role);
            }
          }
        }
        if (this.clientLevelRole == ClientRelationshipManager && data.typeOfUser == this.USER_TYPE_EXTERNAL) {
          this.showMajorRoleRoleDropdown = false;
          this.showEmployeeTypeRadioButtons = false;
        }
        if (this.clientLevelRole == ClientRelationshipManager && data.typeOfUser == this.USER_TYPE_INTERNAL) {
          this.showEmployeeTypeRadioButtons = true;
        }
        if (this.clientLevelRole == SuperUser) {
          this.showMajorRoleRoleDropdown = false;
          this.showEmployeeTypeRadioButtons = false;
          this.showAdminPermissionDropdown = true;
        }
        if (this.clientLevelRole == SystemAdministrator) {
          this.showMajorRoleRoleDropdown = false;
          this.showEmployeeTypeRadioButtons = false;
          this.showAdminPermissionDropdown = false;
        }
        if (this.clientLevelRole == Admin) {
          this.showMajorRoleRoleDropdown = false;
          this.showEmployeeTypeRadioButtons = false;
          this.showAdminPermissionDropdown = false;
        }
        if (this.clientLevelRole !== SuperUser && this.projectLevelRole == ManagerRole) {
          this.showMajorRoleRoleDropdown = false;
          this.showEmployeeTypeRadioButtons = false;
          this.showAdminPermissionDropdown = false;
        }
        if (this.clientLevelRole !== SuperUser && this.projectLevelRole == CoderRole) {
          this.showMajorRoleRoleDropdown = false;
          this.showEmployeeTypeRadioButtons = false;
          this.showAdminPermissionDropdown = false;
        }
        if (this.clientLevelRole !== SuperUser && this.projectLevelRole == QAL1Role) {
          this.showMajorRoleRoleDropdown = false;
          this.showEmployeeTypeRadioButtons = false;
          this.showAdminPermissionDropdown = false;
        }
        if (this.clientLevelRole !== SuperUser && this.projectLevelRole == QAL2Role) {
          this.showMajorRoleRoleDropdown = false;
          this.showEmployeeTypeRadioButtons = false;
          this.showAdminPermissionDropdown = false;
        }
        if (this.clientLevelRole !== SuperUser && this.projectLevelRole == QAL3Role) {
          this.showMajorRoleRoleDropdown = false;
          this.showEmployeeTypeRadioButtons = false;
          this.showAdminPermissionDropdown = false;
        }
      }
    });

    this.adminPermission$ = this.userService.adminPermissionRolesList$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.adminPermissionList = data.permissions;
      } else {
        this.adminPermissionList = [];
        this.adminPermissionDropdown.noRecordsTemplate = "No Records To Display";
      }
    });
    this.checkPreCondition();
    this.adminPermissionMode = "CheckBox";
    this.isAddNewClientIconDisabled = false;
    this.isAddNewProjectIconDisabled = false;
    this.isRemoveProjectIconDisabled = false;
    this.isRemoveClientIconDisabled = false;
    this.isEditAccessDisabled = false;
    this.client$ = this.systemClients.clientListOfLoggedInUser$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.clientList = data;
      } else {
        this.clientList = [];
        this.clientDropdown.noRecordsTemplate = "No Records To Display";
      }
    });
    this.timezone$ = this.timezoneService.timezoneList$.subscribe(data => {
      if (data !== undefined && data !== null && data.length > 0) {
        this.timezoneList = data;
      } else {
        this.timezoneList = [];
        this.timezoneDropdown.noRecordsTemplate = "No Records To Display";
      }
    });
    this.systemClients.getClientListOfLoggedInUser(false);
    this.timezoneService.getTimezoneList();
    if (this.userId !== undefined && this.userId !== null) {
      this.userService.getUserData(this.userId);
    } else {
      this.buildUserForm();
    }
    if(this.userForm) this.userForm.get("typeOfUser").setValue(1);
    this.mode = "CheckBox";

  }

  isCheckedOnEditAccessPermission(userData) {
    if (userData.clients !== undefined && userData.clients !== null) {
      for (const clientIndex in userData.clients) {
        this.accessPermissions[clientIndex] = [];
        for (const projectIndex in userData.clients[clientIndex].projects) {
          this.accessPermissions[clientIndex][projectIndex] = [];
          const project = userData.clients[clientIndex].projects[projectIndex];
          const newAccessPermission = JSON.parse(JSON.stringify(this.accessPermission));
          project["editAccessFields"] = newAccessPermission;
          if (project.leafPermissions !== undefined && project.leafPermissions !== null) {
            for (const editAccessPermission of newAccessPermission) {
              for (const leafPermissions of project.leafPermissions) {
                if (leafPermissions == editAccessPermission.id) {
                  editAccessPermission["isChecked"] = true;
                  break;
                }
              }
              // this.accessPermissions[clientIndex][projectIndex] = editAccessPermission;
            }
            project["editAccessFields"] = {
              dataSource: newAccessPermission, id: "id", text: "name", hasChildren: "hasChild",
              parentID: "parentId"
            };
          }
        }
      }
    }
  }

  checkPreCondition() {
    const userId = sessionStorage.getItem("userId");
    const clientId = sessionStorage.getItem("activeClient");
    if (userId === undefined || userId === null) {
      this.userId = Number(userId);
      this.router.navigate(["/session/signin"]);
    } else if (clientId === undefined || clientId === null) {
      this.router.navigate(["/client"]);
    } else {
      this.userService.getLoggedInPermissions();
      this.timezoneService.getTimezoneList();
      this.getSupervisorList();
      this.roleService.getMajorRoleList([ClientRelationshipManager, SystemAdministrator], true);
      this.getAdminPermissionList();
      this.userService.getFteStatusList();
      this.listUsersService.getEditAccessPermissionList(2, 1);
      this.listUsersService.getEditAccessPermissionListForNodeChecked(2, 1);
    }
  }

  setDefaultProviderPermission(){
      const roleId = ProviderRole;
      const rolePermissions = this.roleService.getPermissionsOfSelectedRole(roleId);
      if (rolePermissions !== undefined && rolePermissions !== null && rolePermissions.resources) {
        const selectedRolesIDs = []; 
        for (const resource of rolePermissions.resources) {
          selectedRolesIDs.push(resource.id);
        }
        this.userForm.controls['clients']['controls'].forEach(element => {
          element.addControl("_provider_role", new FormControl(roleId));
          element.controls['projects']['controls'].forEach(project => {
            project.controls.permissions.setValue(selectedRolesIDs);
            project.controls.leafPermissions.setValue(selectedRolesIDs.map((a) => String(a)));
            project.controls.copyOfLeafPermissions.setValue(selectedRolesIDs.map((a) => String(a)));
          })
        });
      }
  }

  getSupervisorList() {
    this.supervisorQuery = new Query();
    const roleIds = [];
    roleIds.push(ProductOwner, ClientRelationshipManager, SuperUser);
    this.supervisorQuery.addParams("isSupervisor", String(1)).addParams("isActive", String(1))
      .addParams("excludeRoleId", String(roleIds)).addParams("ordering", String("first_name,last_name")).take(20);
  }

  getAdminPermissionList() {
    const permissionIds = [];
    permissionIds.push(UserManagementResource, ProviderResource, GuidelineResource, EducationResource,
      SuperUserAccessResource, AdminResource);
    this.userService.getAdminPermissionList(permissionIds, "-id", true);
  }

  onCancel() {
    this.listUsersService.newUserData.next({type: "activeUser" , value: false});
    this.listUsersService.updateUserData.next({type: "activeUser" , value: false});
    this.dialogRef.close();
    this.router.navigate(["/users/list"]);
  }

  addNewClientSection() {
    const control = this.userForm.controls.clients as FormArray;
    control.insert(0, this.getClientSection());
    this.totalClientCountOnCreation = control.length;
  }


  addNewProjectSection(ix) {
    const control = (this.userForm.controls.clients as FormArray)
      .at(ix)
      .get("projects") as FormArray;
    control.insert(0, this.getProjectSection());

    const project = (this.userForm.controls.clients as FormArray)
      .at(ix)
      .get("projects") as FormArray;
    project.at(ix).get("id").enable();
  }

  removeClientSection(index) {
    const control = this.userForm.controls.clients as FormArray;
    control.removeAt(index);
    this.totalClientCountOnCreation = control.length;
  }

  removeProjectSection(ix, index) {
    const control = (this.userForm.controls.clients as FormArray)
      .at(ix)
      .get("projects") as FormArray;
    control.removeAt(index);
  }

  createNewUser() {
    if (this.isEdit === "false") {
      if (this.loggedInUserDetails.typeOfUser == 2) {
        this.userForm.get("typeOfUser").setValue(2);
      }
      this.validateAllFormFields(this.userForm);
      if (this.userForm.valid) {
        const userData = JSON.parse(JSON.stringify(this.userForm.value));
        if (this.isMajorRoleSelected === false) {
          for (const client of userData.clients) {
            const clientProjects = client['projects'];
            client['projects'] = [];
            for (const project of clientProjects) {
              if (project['ids'] !== undefined && project['ids'] !== null) {
                for (const id of project['ids']) {
                  const projectData = JSON.parse(JSON.stringify(project));
                  projectData.id = id;
                  client.projects.push(projectData);
                }
              }
            }
          }
        } else {
          for (const clientRole in userData.clients) {
            userData.clients[clientRole].role = this.majorRole;
          }
          for (const client of userData.clients) {
            client['projects'] = [];
          }
        }
        this.modifyUserService.createNewUser(userData);
      }
    }
  }

  onMarkAsSupervisorCheckboxChange(event) {
    const isChecked = event.checked;
    if (!isChecked) {
      this.userForm.get("isSupervisor").clearValidators();
      this.userForm.controls.isSupervisor.updateValueAndValidity();
      this.userForm.get("isSupervisor").enable();
    } else {
      this.userForm.get("supervisor").clearValidators();
      this.userForm.controls.supervisor.updateValueAndValidity();
    }
    if (isChecked === false) {
      if (this.isEdit === "true") {
        this.dialog.open(ReassignSupervisorDialogComponent, {
          width: "400px",
          disableClose: true,
          data: {
            selectedUser: this.editUserData.id,
            isChecked: false
          }
        });
      }
    }
  }


  onSupervisorSelect(event) {
    if (event !== undefined && event !== null) {
      this.userForm.patchValue({isSupervisor: false});
    }
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  nodeValueSelected($event: NodeCheckEventArgs, client, project) {
    if ($event.isInteracted) {
      if ("data" in $event && $event.data.length > 0 && "id" in $event.data[0]) {
        const id = $event.data[0]['id'];
        const leafPermission = project.get("leafPermissions").value;
        let leafPermissions = [];
        for (const permission of leafPermission) {
          leafPermissions.push(parseInt(permission));
        }
        if ($event.action === "check") {
          this.addOrRemoveLeafPermissions(false, this.accessPermissionForNodesChecked, leafPermissions, id, false);
          leafPermissions = [...new Set(leafPermissions.map(item => item))];
          leafPermissions.sort((a, b) => a - b);
        } else if ($event.action === "uncheck") {
          leafPermissions = [...new Set(leafPermissions.map(item => item))];
          leafPermissions.sort((a, b) => a - b);
          this.addOrRemoveLeafPermissions(true, this.accessPermissionForNodesChecked, leafPermissions, id, false);
        }
        project.get("leafPermissions").value = leafPermissions;
      }
    }
  }

  addOrRemoveLeafPermissions(isRemove, permissions, leafPermissions, idToDelete, canDelete) {
    for (const permissionsIndex in permissions) {
      let localDelete = false;
      const permission = permissions[permissionsIndex];
      if (canDelete || permission.id == idToDelete) {
        localDelete = true;
      }
      if (permission.subResources !== undefined && permission.subResources !== null
        && permission.subResources.length > 0) {
        this.addOrRemoveLeafPermissions(isRemove, permission.subResources, leafPermissions, idToDelete, localDelete);
      } else {
        if (localDelete) {
          if (isRemove) {
            for (let i = 0; i < leafPermissions.length; i++) {
              if (leafPermissions[i] == permission.id) {
                if (isRemove) {
                  leafPermissions.splice(i, 1);
                  break;
                }
              }
            }
          } else {
            leafPermissions.push(permission.id);
          }
        }
      }
      localDelete = false;
    }
  }

  getProjectListOfSelectedClient(event, client, i) {
    if (event !== undefined && event !== null) {
      const adminRole = client.get("permissions");
      adminRole.enable();
      client.get("role").setValue(this.majorRole);
      const project = client.get("projects") as FormArray;
      project.controls.forEach(data => {
        if (data !== undefined && data !== null) {
          if (data.get('ids') !== undefined && data.get('ids') !== null) {
            data.get('ids').setValue([]);
          }
        }
      });
      const projectListOfSelectedClient$ = this.listProjectsService.getAllProjectList(event.itemData.id, 1);
      this.project$ = projectListOfSelectedClient$.subscribe(data => {
        if (data !== undefined && data !== null && data.results.length > 0) {
          this.userForm.controls.clients['controls'][i].controls.projectList.setValue(data.results);
          client.get("projectList").setValue(data.results);
        } else {
          this.projectDropdown.noRecordsTemplate = "No Records To Display";
        }
      }, (errors) => {
        const manipulatedErrorMessage = this.errorMessageService.manipulateErrorMessageForDisplayingOnSnackBar(errors);
        if (manipulatedErrorMessage !== undefined && manipulatedErrorMessage !== null && manipulatedErrorMessage !== '') {
          this.notificationService.showError(manipulatedErrorMessage);
        }
      });

      this.listProvidersService.getAllProvidersList(event.itemData.id, 1, null, "type,first_name,group_name,facility_name");

      if(this.isProvider){
        this.allProviders$ = this.listProvidersService.allProvidersList$.subscribe(data => {
          if (data !== undefined && data !== null && data.length > 0) {
            this.userForm.controls.clients['controls'][i].controls.providersList.setValue(data);
          }
        });
      }
    }
  }

  getRoleList() {
    this.roleService.getRoleList(null, null, true, 'concurrent');
  }

  ngOnDestroy() {
    if (this.loggedInUserData$) {
      this.loggedInUserData$.unsubscribe();
    }
    if (this.selectedUserData$) {
      this.selectedUserData$.unsubscribe();
    }
    if (this.timezone$) {
      this.timezone$.unsubscribe();
    }
    if (this.updateUser$) {
      this.updateUser$.unsubscribe();
    }
    if (this.supervisor$) {
      this.supervisor$.unsubscribe();
    }
    if (this.majorRole$) {
      this.majorRole$.unsubscribe();
    }
    if (this.client$) {
      this.client$.unsubscribe();
    }
    if (this.adminPermission$) {
      this.adminPermission$.unsubscribe();
    }
    if (this.project$) {
      this.project$.unsubscribe();
    }
    if (this.role$) {
      this.role$.unsubscribe();
    }
    if (this.fte$) {
      this.fte$.unsubscribe();
    }
    if (this.rolePermissions$) {
      this.rolePermissions$.unsubscribe();
    }
    if (this.newUser$) {
      this.newUser$.unsubscribe();
    }
    if (this.actionData$) {
      this.actionData$.unsubscribe();
    }
    if (this.editAccessPermissions$) {
      this.editAccessPermissions$.unsubscribe();
    }
    if (this.editAccessPermissionsForNodesCheckAndUncheck$) {
      this.editAccessPermissionsForNodesCheckAndUncheck$.unsubscribe();
    }
    if(this.allProviders$){
      this.allProviders$.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete()
  }

  onMajorRoleSelection(event) {
    if (event && event.itemData !== undefined && event.itemData !== null) {
      this.isMajorRoleSelected = true;
      this.majorRole = event.itemData.id;
      if (this.isEdit === "false") {
        if (event.itemData.id == SystemAdministrator) {
          this.forceInternalUser = true;
          this.userForm.get("typeOfUser").setValue(this.USER_TYPE_INTERNAL);
          this.isUserTypeInternal = true;
        } else {
          this.forceInternalUser = false;
        }
      }
      const clients = (this.userForm.controls.clients as FormArray);
      for (const client of clients.controls) {
        const theClient = client as FormGroup;
        theClient.removeControl("projects");
      }
    } else {
      this.isMajorRoleSelected = false;
      this.forceInternalUser = false;
      const clients = (this.userForm.controls.clients as FormArray);
      for (const client of clients.controls) {
        const theClient = client as FormGroup;
        theClient.addControl("projects", this.formBuilder.array([this.getProjectSection()]));
      }
      this.majorRole = null;
    }
  }

  getSelectedEmployeeType(event) {
    if (event.value == 2) {
      if (this.totalClientCountOnCreation > 1) {
        this.dialog.open(ExternalUserDialogComponent, {
          width: "400px",
          disableClose: true,
          data: {
            totalClients: this.totalClientCountOnCreation
          }
        });
      } else {
        this.userForm.value.typeOfUser = 2;
        this.isUserTypeInternal = false;
      }
    } else {
      this.userForm.value.typeOfUser = 1;
      this.isUserTypeInternal = true;
    }
  }

  onRoleSelection(event, client, project) {
    if (event && event.itemData !== undefined && event.itemData !== null) {
      const roleId = event.itemData.id;
      this.onRoleChange(roleId, client, project);

      const selectedProjectList = project.get('ids').value ?? [];
      const projectList = client.get('projectList').value ?? [];
      const { isConcurrent } = this.getSelectedProjectDetails(selectedProjectList, projectList)
      const loginType = this.userForm.get('loginType').value
      this.setRoleValidator(project,false);
      if(isConcurrent && loginType === LoginType.SSO){
        this.clearRoleValidator(project)
      }
    }
  }

  onRoleChange(roleId, client, project) {
    const rolePermissions = this.roleService.getPermissionsOfSelectedRole(roleId);
    if (rolePermissions !== undefined && rolePermissions !== null && rolePermissions.resources) {
      const selectedRoleIds = [];
      if(roleId === ClinicianRole){
        selectedRoleIds.push(ProviderQueriesResource)
      }
      for (const resource of rolePermissions.resources) {
        if (resource.subResources.length > 0) {
          for (const subResourcesPermission of resource.subResources) {
            if (subResourcesPermission.subResources.length > 0) {
              for (const subResourcesSubResource of subResourcesPermission.subResources) {
                selectedRoleIds.push(subResourcesSubResource.id);
              }
            } else {
              selectedRoleIds.push(subResourcesPermission.id);
            }
          }
        } else {
          selectedRoleIds.push(resource.id);
        }
      }
      project.get("permissions").setValue(selectedRoleIds);
      project.get("leafPermissions").setValue(selectedRoleIds.map((a) => String(a)));
      project.get("copyOfLeafPermissions").setValue(selectedRoleIds.map((a) => String(a)));
    }
  }

  saveAndCloseEditAccessFlyout(event, client, project) {
    const permissions = project.get("permissions");
    const cachedPermissions = project.get("leafPermissions");
    permissions.setValue(cachedPermissions.value);
    project.get("showEditAccessFlyout").setValue(false);
  }

  closeEditAccessFlyoutWithoutSave($event: MouseEvent, project) {
    const permissions = project.get("permissions");
    const cachedPermissions = project.get("leafPermissions");
    cachedPermissions.setValue(project.value.copyOfLeafPermissions);
    permissions.setValue(project.value.copyOfLeafPermissions);
    project.get("showEditAccessFlyout").setValue(false);
  }

  openEditAccessFlyout($event: MouseEvent, project) {
    project.get("copyOfLeafPermissions").setValue(JSON.parse(JSON.stringify(project.value.leafPermissions)));
    project.get("showEditAccessFlyout").setValue(true);
  }

  toggleEditAccessFlyout($event: MouseEvent, project) {
    project.get("showEditAccessFlyout").setValue(!!!(project.get("showEditAccessFlyout").value));
    if (project.value.showEditAccessFlyout) {
      project.get("copyOfLeafPermissions").setValue(JSON.parse(JSON.stringify(project.value.leafPermissions)));
    }
  }

  updateUserData() {
    if (this.isEdit === "true") {
      if (this.userForm.valid) {
        const userData = JSON.parse(JSON.stringify(this.userForm.value));
        if (this.roleId == ProductOwner) {
          userData.clients = [];
        }
        if (this.clientLevelRole === SuperUser) {
          if (this.clientId == this.userId) {
            userData.clients = [];
          }
        }
        if (this.isMajorRoleSelected === false) {
          const clients = userData.clients;
          let projectPermissionIds = [];
          for (const clientIndex in clients) {
            for (const projectIndex in clients[clientIndex].projects) {
              for (const projectPermissionIndex in clients[clientIndex].projects[projectIndex].leafPermissions) {
                if (this.isLeafPermissions(clients[clientIndex].projects[projectIndex].leafPermissions[projectPermissionIndex], this.accessPermissionForNodesChecked)) {
                  projectPermissionIds.push(clients[clientIndex].projects[projectIndex].leafPermissions[projectPermissionIndex]);
                }
              }
              clients[clientIndex].projects[projectIndex].permissions = projectPermissionIds;
              projectPermissionIds = [];
            }
          }
          if (userData.clients !== undefined && userData.clients !== null && userData.clients.length > 0) {
            for (const client of userData.clients) {
              const clientProjects = client['projects'];
              client['projects'] = [];
              for (const project of clientProjects) {
                if (project['ids'] !== undefined && project['ids'] !== null) {
                  for (const id of project['ids']) {
                    const projectData = JSON.parse(JSON.stringify(project));
                    projectData.id = id;
                    client.projects.push(projectData);
                  }
                }
              }
            }
          }
          if (userData.clients !== undefined && userData.clients !== null && userData.clients.length > 0) {
            for (const clientRole in userData.clients) {
              userData.clients[clientRole].role = null;
            }
          }
        } else {
          if (userData.clients !== undefined && userData.clients !== null && userData.clients.length > 0) {
            for (const clientRole in userData.clients) {
              userData.clients[clientRole].role = this.majorRole;
            }
            for (const client of userData.clients) {
              client['projects'] = [];
            }
          }
        }

        this.modifyUserService.updateUserData(this.userId, userData);
        this.updateUser$ = this.modifyUserService.updateUserDetailsList$.subscribe(data => {
          if (data !== undefined && data !== null) {
            if (data.isActive === true) {
              this.listUsersService.updateUserData.next({type: "activeUser" , value: true});
            } else {
              this.listUsersService.updateUserData.next({type: "inactiveUser" , value: true});
            }
            this.dialogRef.close();
            this.router.navigate(["/users/list"]);
          }
        });
      }
    }
  }

  isLeafPermissions(permissionId, accessPermissions) {
    for (const permission of accessPermissions) {
      if (permission.id == permissionId) {
        if (permission.subResources === undefined || permission.subResources === null
          || permission.subResources.length === 0) {
          return true;
        }
      } else {
        for (const subPermission of permission.subResources) {
          if (subPermission.id == permissionId) {
            if (subPermission.subResources === undefined || subPermission.subResources === null
              || subPermission.subResources.length === 0) {
              return true;
            }
          } else {
            for (const subPermissionSubPermissions of subPermission.subResources) {
              if (subPermissionSubPermissions.id == permissionId) {
                if (subPermissionSubPermissions.subResources === undefined || subPermissionSubPermissions.subResources === null
                  || subPermissionSubPermissions.subResources.length === 0) {
                  return true;
                }
              }
            }
          }
        }
      }
    }
    return false;
  }

  searchTimezone(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        this.timezoneDropdown.noRecordsTemplate = "No Records To Display";
      }
    }
  }

  searchSupervisor(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        this.supervisorDropdown.noRecordsTemplate = "No Records To Display";
      }
    }
  }

  searchClient(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        this.clientDropdown.noRecordsTemplate = "No Records To Display";
      }
    }
  }

  searchProject(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        this.projectDropdown.noRecordsTemplate = "No Records To Display";
      }
    }
  }

  searchFteStatus(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        this.fteStatusDropdown.noRecordsTemplate = "No Records To Display";
      }
    }
  }

  searchMajorRole(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        if(this.majorRoleDropdown != undefined){
          this.majorRoleDropdown.noRecordsTemplate = "No Records To Display";
        }
      }
    }
  }

  searchAdminPermission(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        this.adminPermissionDropdown.noRecordsTemplate = "No Records To Display";
      }
    }
  }

  searchRole(event) {
    if (event !== undefined && event !== null) {
      if (event.text !== undefined && event.text !== null && event.text !== "") {
        this.roleDropdown.noRecordsTemplate = "No Records To Display";
      }
    }
  }

  createNewProvider() {
    this.setDefaultProviderPermission()
    var clientData=[];
    this.userForm.value.clients.forEach(element => {
      var projectData=[];
      let index = this.clientList.findIndex(item => item.id == element.id);
      var clientNameData = "";
      if(index > -1){
        clientNameData = this.clientList[index].name;
      }

      element.projects.forEach(element1 => {
        let index = element.projectList.findIndex(item => item.id == element1.ids[0]);
        var nameData = "";
        if(index > -1){
          nameData = element.projectList[index].name;
        }
        var project = {
          id: element1.ids[0],
          name: nameData,
          role: ProviderRole,
          permissions: element1.permissions,
          fteStatus: 1
        };
        projectData.push(project);
      });
      var client = {
        id:element.id,
        name:clientNameData,
        projects:projectData,
        permissions:element.permissions,
        providers:element.providers
      }
      clientData.push(client);
    });
    let formData = {
      timezone : this.userForm.value.timezone,
      typeOfUser: 2,
      loginType : this.userForm.value.loginType,
      isSupervisor:false,
      majorRole:false,
      clients : clientData,
      isCreateProvider: true
      // providerId : this.userForm.value.clients[0].providers,
      // projectIds :this.userForm.value.clients[0].projects[0].ids,
      // activeClientId: sessionStorage.getItem('activeClient')
    }

    if (this.isProvider && this.isEdit === "false"){
      this.modifyUserService.createNewUser(formData);
      this.newUser$ = this.modifyUserService.newUserData$.subscribe(data => {
        if (data !== undefined && data !== null) {
          if (data.isActive === true) {
            this.listUsersService.newUserData.next({type: "activeUser" , value: true});
          } else {
            this.listUsersService.newUserData.next({type: "inactiveUser" , value: true});
          }
        this.dialogRef.close();
        }
      });
    } else if (this.isEdit === "false") {
      this.validateAllFormFields(this.userForm);
      if (this.userForm.valid) {
        const userData = JSON.parse(JSON.stringify(this.userForm.value));
        if (this.isMajorRoleSelected === false) {
          for (const client of userData.clients) {
            const clientProjects = client['projects'];
            client['projects'] = [];
            for (const project of clientProjects) {
              if (project['ids'] !== undefined && project['ids'] !== null) {
                for (const id of project['ids']) {
                  const projectData = JSON.parse(JSON.stringify(project));
                  projectData.id = id;
                  client.projects.push(projectData);
                }
              }
            }
          }
        } else {
          for (const clientRole in userData.clients) {
            userData.clients[clientRole].role = this.majorRole;
          }
          for (const client of userData.clients) {
            client['projects'] = [];
          }
        }
        this.modifyUserService.createNewUser(userData);
        this.newUser$ = this.modifyUserService.newUserData$.subscribe(data => {
          this.dialog.closeAll();
          if (data !== undefined && data !== null) {
            if (data.isActive === true) {
              this.listUsersService.newUserData.next({type: "activeUser" , value: true});
            } else {
              this.listUsersService.newUserData.next({type: "inactiveUser" , value: true});
            }
            this.dialogRef.close();
          }
        });
      }
    }
  }

  updateProviderData(){
    if (this.userForm.valid) {
      var clientData=[];
      this.userForm.value.clients.forEach(element => {
        var projectData=[];
        let index = this.clientList.findIndex(item => item.id == element.id);
        var clientNameData = "";
        if(index > -1){
          clientNameData = this.clientList[index].name;
        }

        element.projects.forEach(element1 => {
          let index = element.projectList.findIndex(item => item.id == element1.ids[0]);
          var nameData = "";
          if(index > -1){
            nameData = element.projectList[index].name;
          }
          var project = {
            id: element1.ids[0],
            name: nameData,
            permissions: (typeof(element1.permissions[0]) === 'object') ? element1.permissions.map(permission => permission.id) : element1.permissions,
            fteStatus: 1,
          };
          projectData.push(project);
        });
        var client = {
          id:element.id,
          name:clientNameData,
          projects:projectData,
          permissions:element.permissions,
          providers:element.providers
        }
        clientData.push(client);
      });
      let formData = {
        timezone : this.userForm.value.timezone,
        typeOfUser: 2,
        loginType : this.userForm.value.loginType,
        isSupervisor:false,
        majorRole:false,
        clients : clientData,
      }

      this.modifyUserService.updateUserData(this.userId, formData);
      this.updateUser$ = this.modifyUserService.updateUserDetailsList.subscribe(data => {
        if (data !== undefined && data !== null) {
          if (data.isActive === true) {
            this.listUsersService.newUserData.next({type: "activeUser" , value: true});
          } else {
            this.listUsersService.newUserData.next({type: "inactiveUser" , value: true});
          }
        this.dialogRef.close();
        }
      });
    }
  }


  /**
   *   Get a project form control by client index and project index  
   * */ 
  getProjectControl(clientIndex: number, projectIndex: number, controlName: string) {
    return ((this.userForm.get('clients') as FormArray).at(clientIndex)
      .get('projects') as FormArray).at(projectIndex).get(controlName);
  }

  setRoleValidator(projectControl: FormControl,clearValue:boolean=true) {
    if(clearValue) projectControl.get('role').patchValue(null)
    projectControl.get('role').setValidators(roleValidator())
    projectControl.updateValueAndValidity()
  }

  clearRoleValidator(projectControl: FormControl){
    projectControl.get('role').clearValidators()
    projectControl.updateValueAndValidity()
  }

  setProjectRoleList(selectedProjectIds: number[], onSubscribeFn: (value) => void){
    if (selectedProjectIds.length) {
        this.roleService.getRolesByProjectIds(selectedProjectIds)
        .pipe(takeUntil(this.destroy$))
        .subscribe((value) => {
          onSubscribeFn(value)
        });
    } else {
      this.roleDropdown.noRecordsTemplate = "No Records To Display";
    }
  }

  onProjectSelection($event, projectControl: FormControl, projectList: Record<string, any>){
    const { isConcurrent } = this.getSelectedProjectDetails($event.value, projectList)
    const loginType = this.userForm.get('loginType').value
    this.setProjectRoleList($event.value, (value) => { projectControl.get('roleList').setValue(value.results) })
    this.setRoleValidator(projectControl)
    if(isConcurrent && loginType === LoginType.SSO){
      this.clearRoleValidator(projectControl)
    }
  }

  getSelectedProjectDetails(selectedProjectIds: number[], projectList){
    const selectedProjects = projectList.filter(project => selectedProjectIds.includes(project.id))
    const isConcurrent = selectedProjects.every(project => project.type === Concurrent)
    return {isConcurrent, selectedProjects}
  }

  subscribeToLoginTypeChange() {
    this.userForm.get('loginType').valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      for(let clientControl of ((this.userForm.get('clients')) as FormArray).controls){
        clientControl.get('projects').patchValue(null)
        for(let [index, projectControl] of (clientControl.get('projects') as FormArray).controls.entries()){
            const projectList = clientControl.get('projectList')
            const { isConcurrent } = this.getSelectedProjectDetails(projectControl.get('ids').value, projectList.value)
            projectControl.get('role').patchValue(null)
            if(value === LoginType.SSO && isConcurrent){
              this.clearRoleValidator(projectControl as FormControl)
            }else{
              this.setRoleValidator(projectControl as FormControl)
            }

          }
        }
    })
  }
}

